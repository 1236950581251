import styled from 'styled-components';

const StyledSafetyDatagridWrapper = styled.div`
  display: flex;
  flex-direction: column;

  /* width: 1776px; */
  width: 100%;
  height: 100%;
  padding-right: 40px;

  flex: 1;
`;

export default StyledSafetyDatagridWrapper;
