const updateGridData = ({
  gridRef,
  variables,
  newData,
  isUpdate,
  key,
  removeNodeDataList,
}) => {
  const gridAPI = variables?.gridParam?.api || gridRef?.current?.api;

  if (isUpdate) {
    let targetNode = null;

    gridAPI?.forEachNode(node => {
      if (node?.data?.[key || 'seq'] === newData?.[key || 'seq']) {
        targetNode = node;
      }
    });

    if (targetNode) {
      targetNode?.setData({ ...targetNode?.data, ...newData });
      gridAPI?.refreshClientSideRowModel();
    }
  } else {
    gridAPI?.applyTransaction({
      remove: removeNodeDataList || [variables?.gridParam?.node?.data],
    });
  }
};

export default updateGridData;
