import styled from 'styled-components';

const StyledSidebarMenuWrapper = styled.div`
  width: 270px;
  height: 100%;
  background-color: white;
  padding-top: 20px;
`;

export default StyledSidebarMenuWrapper;
