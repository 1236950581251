import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { ALERT_MODAL_STATUS } from '../../../constant/Common/Modal/Alert/status';
import { PERIOD_OPTION } from '../../../constant/Common/Option/Date';
import {
  PATH_COMMON_BUTTON_ICON,
  PATH_HYPHEN_ICON,
} from '../../../constant/Common/Path/Asset/Icon/Button';
import { DAILY_IN_OUT_EQUIPMENT_OPTION } from '../../../constant/DailyInOut/elements';
import { createOptionalSelectOption } from '../../../util/Common/Option/Generator/selectOptionCreator';
import changePeriod from '../../../util/Common/Option/Handler/changePeriod';
import checkDateDiff from '../../../util/Common/Validator/Handler/checkDateDiff';
import CommonButton from '../../Common/Controller/Button';
import CommonDate from '../../Common/Controller/Date/index';
import CommonSelect from '../../Common/Controller/Select/index';
import CommonTextInput from '../../Common/Controller/Text';
import CommonMessageModalFrame from '../../Common/Modal/Message/Frame';

const AdvancedSearchModal = ({
  isSearchModalOpen,
  setIsSearchModalOpen,
  yardOption,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  yard,
  setYard,
  setIsMessageOpen,
  setMessage,
  setMessageStatus,
  equipmentType,
  setEquipmentType,
  equipment,
  setEquipment,
}) => {
  const searchController = useForm();
  const [period, setPeriod] = useState('');
  const [isEquipmentTypeSelected, setIsEquipmentTypeSelected] = useState(
    !!searchController?.getValues()?.equipment_type,
  );

  useEffect(() => {
    setIsEquipmentTypeSelected(!!searchController?.getValues()?.equipment_type);
  }, [searchController?.getValues()?.equipment_type]);

  const onSearch = () => {
    if (
      !checkDateDiff({
        fromDate: searchController.getValues('fromDate'),
        toDate: searchController.getValues('toDate'),
      }) &&
      !isEquipmentTypeSelected
    ) {
      setMessageStatus(ALERT_MODAL_STATUS.ERROR);
      setMessage('The date range cannot exceed one year. Please try again.');
      setIsMessageOpen(true);

      return;
    }
    setPeriod(searchController?.getValues()?.period);

    if (!isEquipmentTypeSelected) {
      setFromDate(searchController?.getValues()?.fromDate);
      setToDate(searchController?.getValues()?.toDate);
    } else {
      setFromDate('');
      setToDate('');
    }
    if (searchController?.getValues()?.yard) {
      setYard(searchController?.getValues()?.yard);
    } else {
      setYard('');
    }
    if (searchController?.getValues()?.equipment_type) {
      setEquipmentType(searchController?.getValues()?.equipment_type);
    } else {
      setEquipmentType('');
    }
    if (searchController?.getValues()?.equipment) {
      setEquipment(searchController?.getValues()?.equipment);
    } else {
      setEquipment('');
    }
    setIsSearchModalOpen?.(false);
  };

  return (
    <CommonMessageModalFrame
      isOpen={isSearchModalOpen}
      onCloseButtonClick={() => {
        searchController.reset();
        setIsSearchModalOpen(false);
      }}
      header="Advanced Search"
      content={
        <FormProvider {...searchController}>
          <form>
            <div className="bg-[#F9F9F9] pt-[19px] pl-[30px] pr-[30px]">
              <div className="flex flex-col">
                <div className="flex">
                  <div className="mr-[20px]">
                    <CommonSelect
                      inputName="yard"
                      label="YARD"
                      width="w-[153px]"
                      option={yardOption}
                      defaultValue={yard}
                      onChangeHandler={({ e, onChange }) => {
                        onChange(e?.target?.value);
                      }}
                    />
                  </div>
                  <div className="mr-[20px]">
                    <CommonSelect
                      inputName="equipment_type"
                      label="EQUIP"
                      width="w-[116px]"
                      option={createOptionalSelectOption({
                        items: DAILY_IN_OUT_EQUIPMENT_OPTION,
                        labelKey: 'label',
                      })}
                      defaultValue={equipmentType}
                      onChangeHandler={({ e, onChange }) => {
                        onChange(
                          DAILY_IN_OUT_EQUIPMENT_OPTION?.find(
                            item => item?.value === e?.target?.value,
                          )?.label || '',
                        );
                        if (e?.target?.value === '') {
                          searchController.setValue('period', period || '');
                          searchController.setValue(
                            'fromDate',
                            fromDate ||
                              dayjs().subtract(1, 'week').format('YYYY-MM-DD'),
                          );
                          searchController.setValue(
                            'toDate',
                            toDate || dayjs().format('YYYY-MM-DD'),
                          );
                          setIsEquipmentTypeSelected(false);
                        } else {
                          searchController.setValue('period', '');
                          searchController.setValue('fromDate', '');
                          searchController.setValue('toDate', '');
                          setIsEquipmentTypeSelected(true);
                          searchController.clearErrors('equipment');
                        }
                        searchController.setValue('equipment', '');
                      }}
                    />
                  </div>
                  <div className="mr-[20px]">
                    <CommonTextInput
                      inputName="equipment"
                      width="w-[116px]"
                      label=""
                      required={isEquipmentTypeSelected}
                      disabled={!isEquipmentTypeSelected}
                      defaultValue={equipment}
                      onChangeHandler={({ e, onChange }) => {
                        onChange(e?.target?.value?.toUpperCase());
                      }}
                    />
                  </div>
                </div>
                <div className="flex">
                  <div className="w-[153px] mr-[20px]">
                    <CommonSelect
                      width="w-[153px]"
                      label="PERIOD"
                      defaultValue={period}
                      option={PERIOD_OPTION}
                      inputName="period"
                      disabled={isEquipmentTypeSelected}
                      onChangeHandler={({ e, onChange }) => {
                        onChange(e?.target?.value);
                        const dateRange = changePeriod(e?.target?.value);
                        searchController.setValue(
                          'fromDate',
                          dateRange?.fromDate,
                        );
                        searchController.setValue('toDate', dateRange?.toDate);
                      }}
                    />
                  </div>
                  <div className="w-[116px]">
                    <CommonDate
                      label=""
                      inputName="fromDate"
                      width="w-[116px]"
                      defaultValue={fromDate}
                      required={!isEquipmentTypeSelected}
                      disabled={isEquipmentTypeSelected}
                      onChangeHandler={({ e, onChange }) => {
                        const value = dayjs(e)?.isValid()
                          ? dayjs(e)?.format('YYYY-MM-DD')
                          : null;
                        onChange?.(value);
                        searchController.setValue('period', '');
                      }}
                    />
                  </div>
                  <div className="ml-[4px] mr-[4px] flex items-center">
                    <img src={PATH_HYPHEN_ICON} alt="hyphenIcon" width="13px" />
                  </div>
                  <div className="w-[116px]">
                    <CommonDate
                      label=""
                      inputName="toDate"
                      width="w-[116px]"
                      defaultValue={toDate}
                      required={!isEquipmentTypeSelected}
                      disabled={isEquipmentTypeSelected}
                      onChangeHandler={({ e, onChange }) => {
                        const value = dayjs(e)?.isValid()
                          ? dayjs(e)?.format('YYYY-MM-DD')
                          : null;
                        onChange?.(value);
                        searchController.setValue('period', '');
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </form>
        </FormProvider>
      }
      button={
        <div className="flex justify-center space-x-[18px]">
          <div>
            <CommonButton
              icon={PATH_COMMON_BUTTON_ICON.cancelRed}
              styles={{
                hoverBackgroundColor: '#B74747',
                border: '1px solid #B74747',
                hoverBorder: '1px solid #B74747',
                hoverColor: '#fff',
                color: '#B74747',
              }}
              onClick={() => {
                searchController.reset();
                setIsSearchModalOpen(false);
              }}
            >
              Cancel
            </CommonButton>
          </div>
          <div>
            <CommonButton
              type="submit"
              icon={PATH_COMMON_BUTTON_ICON.searchBlue}
              styles={{
                hoverBackgroundColor: '#556DA1',
                border: '1px solid #556DA1',
                hoverBorder: '1px solid #556DA1',
                hoverColor: '#fff',
                color: '#556DA1',
              }}
              onClick={searchController.handleSubmit(onSearch)}
            >
              Search
            </CommonButton>
          </div>
        </div>
      }
    />
  );
};

export default AdvancedSearchModal;
