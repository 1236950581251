import React, { useRef } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import CommonMessageModalFrame from '../../../Common/Modal/Message/Frame';
import ModalButton from './ModalButton';
import ModalContent from './ModalContent';
import { generateFormattedDay } from '../../../../util/Inventory/Common/Generator/formattedDayGenerator';
import { INVENTORY_EDIT_HEADER } from '../../../../constant/Inventory/Value';
import checkContainerNumberConstraint from '../../../../util/Common/Validator/Handler/checkContainerNumberConstraint';
import checkChassisNumberConstraint from '../../../../util/Common/Validator/Handler/checkChassisNumberConstraint';
import {
  ERROR_INVALID_CHASSIS_NUMBER,
  ERROR_INVALID_CONTAINER_NUMBER,
} from '../../../../constant/Common/Error/message';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import updateButtonDisabled from '../../../../util/Common/Controller/Handler/updateButtonDisabled';

const EditModal = ({
  isOpen,
  setOpen,
  equip,
  specificData,
  setSpecificSeq,
  selectOptions,
  mutate,
  isEditable,
  setAlertModalStatus,
  setAlertOpen,
}) => {
  const editController = useForm();

  const saveButtonRef = useRef(null);

  const handleDataPatch = submitData => {
    updateButtonDisabled({ ref: saveButtonRef, disabled: true });
    if (
      submitData?.cont_no &&
      !checkContainerNumberConstraint({
        containerNo: submitData.cont_no,
        containerSize: submitData.size,
        containerType: submitData.type,
      })
    ) {
      updateButtonDisabled({ ref: saveButtonRef, disabled: false });
      setAlertModalStatus(prev => {
        return {
          status: ALERT_MODAL_STATUS?.ERROR,
          message: ERROR_INVALID_CONTAINER_NUMBER,
        };
      });
      setAlertOpen(true);

      return;
    }

    const data = {
      ...submitData,
      created_date: generateFormattedDay(submitData.created_date, 'YYYY-MM-DD'),
      created_time: generateFormattedDay(submitData.created_time, 'HH:mm:ss'),
    };

    mutate({ equip, seq: specificData?.seq, data, buttonRef: saveButtonRef });
  };

  return (
    <FormProvider {...editController}>
      <form onSubmit={editController?.handleSubmit(handleDataPatch)}>
        <CommonMessageModalFrame
          isOpen={isOpen}
          setOpen={setOpen}
          onCloseButtonClick={() => {
            setSpecificSeq(null);
            setOpen(false);
          }}
          styles={{
            width: '591px',
          }}
          header={`Edit ${INVENTORY_EDIT_HEADER?.[equip]}`}
          content={
            <ModalContent
              equip={equip}
              data={specificData}
              selectOptions={selectOptions}
              isEditable={isEditable}
            />
          }
          button={
            isEditable ? <ModalButton saveButtonRef={saveButtonRef} /> : <div />
          }
        />
      </form>
    </FormProvider>
  );
};

export default EditModal;
