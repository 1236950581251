/**
 * @description
 * Function to handle the sidebar open.
 * @param {function} setIsSidebarOpen
 * Function to handle the sidebar open state.
 * @param {HTMLElement} sidebarElement
 * Sidebar element.
 */
export const openSidebar = ({ setIsSidebarOpen, sidebarElement }) => {
  setIsSidebarOpen?.(prev => true);
  sidebarElement.style.width = '450px';
};

/**
 * @description
 * Function to handle the sidebar close.
 * @param {function} setIsSidebarOpen
 * Function to handle the sidebar open state.
 * @param {HTMLElement} sidebarElement
 * Sidebar element.
 */
export const closeSidebar = ({ setIsSidebarOpen, sidebarElement }) => {
  setIsSidebarOpen?.(prev => false);
  sidebarElement.style.width = '67px';
};
