const OPTIONS_LABEL_SX = {
  width: '100%',
  margin: 0,
  py: '2px',
  pl: '4px',
  pr: '10px',
  '.MuiTypography-root': {
    fontSize: '11px',
    fontWeight: '500',
    color: '#222222',
    whiteSpace: 'nowrap',
    paddingLeft: '8px',
    paddingTop: '1px',
  },
};

const OPTIONS_CHECKBOX_SX = {
  border: 'unset',
  color: '#C4C4C4',
  width: '12px',
  height: '12px',
  '& .MuiSvgIcon-root': { fontSize: 14 },
};

const ALL_LABEL_SX = {
  ...OPTIONS_LABEL_SX,
  background: '#F2F4F8',
  '.MuiTypography-root': {
    ...OPTIONS_LABEL_SX?.['.MuiTypography-root'],
    color: '#264B9F',
  },
};

const ALL_CHECKBOX_SX = {
  ...OPTIONS_CHECKBOX_SX,
  '& .MuiSvgIcon-root': {
    ...OPTIONS_CHECKBOX_SX?.['& .MuiSvgIcon-root'],
    color: '#264B9F',
  },
};

export { OPTIONS_LABEL_SX, OPTIONS_CHECKBOX_SX, ALL_LABEL_SX, ALL_CHECKBOX_SX };
