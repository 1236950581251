import styled from 'styled-components';
import { PRIMARY_COLOR } from '../../../../../../../../constant/Common/Style/color';

const StyledSubMenuNavigatorItemWrapper = styled.div`
  height: 100%;
  background-color: transparent;
  color: ${props => (props?.$isCurrentMenu ? PRIMARY_COLOR : '#00000099')};
  font-weight: ${props => (props?.$isCurrentMenu ? 'bold' : 500)};

  white-space: nowrap;
  transition-duration: 300ms;
  &:hover {
    color: ${props => (props?.$isCurrentMenu ? PRIMARY_COLOR : `#000000`)};
  }
`;

export default StyledSubMenuNavigatorItemWrapper;
