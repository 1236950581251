import styled from 'styled-components';

const StyledUtilityBarWrapper = styled.div`
  padding-right: 40px;
  width: 100%;
  /* min-width: 1000px; */
  height: fit-content;
`;

export default StyledUtilityBarWrapper;
