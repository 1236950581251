import styled from 'styled-components';

const StyledSidebarSubMenuWrapper = styled.div`
  width: 400px;
  height: fit-content;

  position: absolute;
  top: 7px;
  left: 270px;

  background-color: transparent;
`;

export default StyledSidebarSubMenuWrapper;
