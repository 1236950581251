import styled from 'styled-components';

const StyledSubMenuNavigatorWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 108px;
  min-height: 108px;
  padding-top: 28px;
  padding-left: 29px;
  gap: 10px;
  border-bottom: 1px solid rgb(229, 231, 235);
`;

export default StyledSubMenuNavigatorWrapper;
