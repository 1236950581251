import React from 'react';
import { useFormContext } from 'react-hook-form';
import { PATH_COMMON_BUTTON_ICON } from '../../../../../constant/Common/Path/Asset/Icon/Button';
import {
  PRESET_PRIMARY,
  PRESET_RED,
} from '../../../../../constant/Common/Style/preset';
import StyledModalButtonWrapper from '../../../../../style/Inventory/Modal/StyledModalButtonWrapper';
import CommonButton from '../../../../Common/Controller/Button';

const ModalButton = ({ saveButtonRef }) => {
  const { reset, getValues } = useFormContext();
  return (
    <StyledModalButtonWrapper>
      <CommonButton
        icon={PATH_COMMON_BUTTON_ICON.clearRed}
        styles={PRESET_RED}
        onClick={() => {
          reset({
            created_date: getValues('created_date'),
            created_time: getValues('created_time'),
            yard: getValues('yard'),
          });
        }}
      >
        Clear
      </CommonButton>
      <CommonButton
        ref={saveButtonRef}
        type="submit"
        icon={PATH_COMMON_BUTTON_ICON.saveBlue}
        styles={PRESET_PRIMARY}
      >
        Save
      </CommonButton>
    </StyledModalButtonWrapper>
  );
};

export default ModalButton;
