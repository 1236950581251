import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import CommonMessageModalFrame from '../../../Common/Modal/Message/Frame';
import SafetyDatagrid from '../../Common/SafetyDatagrid';
import createMRColumnDefs from '../../../../util/Safety/MR/Generator/createMRColumnDefs';
import UtilityBar from '../../Common/UtilityBar';
import ModalHeader from '../../Common/Modal/ModalHeader';
import ModalButton from '../../Common/Modal/ModalButton';
import { EDIT_MODE } from '../../../../constant/Safety/Common/mode';
import ModalContent from '../Modal/ModalContent';
import useGetListData from '../../../../hook/Safety/MR/Get/useGetListData';
import useSpecificMRData from '../../../../hook/Safety/MR/Get/useSpecificMRData';
import createModalDefaultValue from '../../../../util/Safety/MR/Generator/createModalDefaultValue';
import CommonLoading from '../../../Common/Loading';
import usePatchSpecificMRData from '../../../../hook/Safety/MR/Patch/usePatchSpecificMRData';
import { ALERT_MODAL_STATUS } from '../../../../constant/Common/Modal/Alert/status';
import CommonAlert from '../../../Common/Modal/Alert';
import usePostMRData from '../../../../hook/Safety/MR/Post/usePostMRData';
import useControlLoadingStatus from '../../../../hook/Safety/Common/Loading/useControlLoadingStatus';
import saveMR from '../../../../util/Safety/MR/Handler/Save/saveMR';
import clickOKButton from '../../../../util/Safety/Common/Handler/Save/okButtonHandler';
import useGetGridFilterOption from '../../../../hook/Safety/Common/Option/useGetGridFilterOption';
import checkPermission from '../../../../util/Common/Router/Handler/checkPermission';
import { PERM_SAFETY_MR_EDIT } from '../../../../constant/Common/Permission';

const SafetyMRContents = () => {
  /** Essential */
  const gridRef = useRef(null);
  const saveButtonRef = useRef(null);
  const isEditable = () =>
    checkPermission({ permission: [PERM_SAFETY_MR_EDIT] });

  const [isModalOpen, setModalOpen] = useState(false);
  const [editMode, setEditMode] = useState(EDIT_MODE?.add);

  /** Message modal opener */
  const [isAlertOpen, setAlertOpen] = useState(false);
  const [alertModalStatus, setAlertModalStatus] = useState({
    message: '',
    status: ALERT_MODAL_STATUS?.STABLE,
  });
  const alertDataSetter = { setAlertOpen, setAlertModalStatus, setModalOpen };

  /** Grid, Modal data */
  const { listData, isLoading: loadingGetList } = useGetListData({
    ...alertDataSetter,
  });
  const {
    specificData,
    seq,
    setSeq,
    isLoading: loadingSpecificData,
    isFetching: fetchingSpecificData,
    isRefetching: refetchingSpecificData,
    isError: errorSpecificData,
  } = useSpecificMRData({ isModalOpen, ...alertDataSetter });

  /** Set edit mode and form default value */
  const [formDefaultValue, setFormDefaultValue] = useState(
    createModalDefaultValue({ specificData, editMode }),
  );

  const formController = useForm({
    defaultValues: formDefaultValue,
    shouldUnregister: true,
  });
  useEffect(() => {
    const newFormDefaultValue = createModalDefaultValue({
      specificData,
      editMode,
    });
    setFormDefaultValue(prev => newFormDefaultValue);
    formController?.reset(newFormDefaultValue);
  }, [editMode, isModalOpen, specificData]);

  /** Data patcher */
  const { mutate: editMutate, isPending: editMutatePending } =
    usePatchSpecificMRData({
      gridRef,
      setAlertModalStatus,
      setAlertOpen,
      setModalOpen,
    });
  const { mutate: addMutate, isPending: addMutatePending } = usePostMRData({
    setAlertModalStatus,
    setAlertOpen,
    setModalOpen,
  });

  /** Loading component opener */
  const { isLoadingOpen, setLoadingOpen } = useControlLoadingStatus({
    isFetching: [fetchingSpecificData],
    isLoading: [loadingGetList, loadingSpecificData],
    isPending: [],
  });

  /** Filter option */
  const gridFilterOption = useGetGridFilterOption();
  const { filterDivisionList } = gridFilterOption;

  /** Grid column definition */
  const [columnDefs, setColumnDefs] = useState(
    createMRColumnDefs({ ...gridFilterOption }),
  );

  return (
    <>
      <div className="pl-[29px] flex flex-col flex-1 pb-8">
        <UtilityBar
          setModalOpen={setModalOpen}
          setEditMode={setEditMode}
          gridRef={gridRef}
          isEditable={isEditable()}
          subPage="MR"
        />

        {filterDivisionList?.length > 1 && (
          <SafetyDatagrid
            gridRef={gridRef}
            columnDefs={columnDefs}
            data={listData}
            setSpecificSeq={setSeq}
            setModalOpen={setModalOpen}
            setEditMode={setEditMode}
          />
        )}
      </div>
      {isModalOpen && !loadingSpecificData && (
        <CommonMessageModalFrame
          isOpen={isModalOpen}
          setOpen={setModalOpen}
          header={<ModalHeader editMode={editMode} title="M&R" />}
          content={
            <ModalContent
              formController={formController}
              data={formDefaultValue}
              isEditable={isEditable()}
            />
          }
          button={
            <ModalButton
              onClearClick={() => {
                formController?.reset({
                  ...createModalDefaultValue({
                    specificData,
                    editMode,
                  }),
                });
              }}
              onSaveClick={() => {
                formController?.handleSubmit(data =>
                  saveMR?.({
                    data,
                    editMutate,
                    addMutate,
                    seq,
                    editMode,
                    saveButtonRef,
                  }),
                )();
              }}
              isEditable={isEditable()}
              saveButtonRef={saveButtonRef}
            />
          }
        />
      )}
      {/* Loading */}
      {isLoadingOpen && <CommonLoading isOpen={isLoadingOpen} />}
      {/* Alert */}
      {isAlertOpen &&
        alertModalStatus?.status !== ALERT_MODAL_STATUS?.SUCCESS && (
          <CommonAlert
            isOpen={isAlertOpen}
            setOpen={setAlertOpen}
            status={alertModalStatus?.status}
            content={alertModalStatus?.message}
            onOKButtonClick={() => {
              clickOKButton?.({
                setAlertOpen,
                setModalOpen,
                status: alertModalStatus?.status,
              });
            }}
          />
        )}
    </>
  );
};

export default SafetyMRContents;
