import styled from 'styled-components';

const StyledSubMenuNavigatorHeader = styled.div`
  width: fit-content;
  height: fit-content;
  color: #000;
  font-size: 25px;
  font-style: normal;
  font-weight: 800;
  line-height: normal;
`;

export default StyledSubMenuNavigatorHeader;
