import { memo } from 'react';
import styled from 'styled-components';

const StyledSidebarSubMenuItem = memo(
  styled.div`
    display: flex;
    align-items: center;

    width: 100%;
    height: 48px;
    padding-left: 20px;

    font-size: 14px;
    color: ${props =>
      props?.$isCurrentMenu ? `#001E5F` : `rgba(0, 0, 0, 0.6)`};
    font-weight: ${props => (props?.$isCurrentMenu ? `bold` : `normal`)};

    transition-duration: 300ms;
    &:hover {
      color: rgba(0, 0, 0, 0.87);
      background-color: #e5e7eb;
    }
  `,
  null,
);

export default StyledSidebarSubMenuItem;
