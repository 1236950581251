import { useQuery } from '@tanstack/react-query';
import getDailyInOutList from '../../../service/DailyInOut/Get/getDailyInOutList';
import dailyInOutKeys from '../keys';

const useGetDailyInOutList = ({
  div,
  fromDate,
  toDate,
  yard,
  equipmentType,
  equipment,
}) => {
  const {
    isLoading: isLoadingDailyInOutList,
    data,
    isRefetching: isRefetchingDailyInOutList,
    isError: isErrorDailyInOutList,
  } = useQuery({
    queryKey: dailyInOutKeys?.list({
      div,
      fromDate,
      toDate,
      yard,
      equipmentType,
      equipment,
    }),
    queryFn: () =>
      getDailyInOutList({
        div,
        fromDate,
        toDate,
        yard,
        equipmentType,
        equipment,
      }),
    refetchOnWindowFocus: false,
  });

  return {
    isLoadingDailyInOutList,
    dailyInOutList: data?.data,
    isRefetchingDailyInOutList,
    isErrorDailyInOutList,
  };
};

export default useGetDailyInOutList;
