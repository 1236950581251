/**
 * @description
 * Function to handle outside click event in division selector component
 * @param {object} ref
 * Reference to the division selector component
 * @param {*} event
 * Event object
 * @param {*} isOpen
 * Flag to check if the division selector is open
 * @param {*} setIsOpen
 * Function to set the open state of the division selector
 */
const handleOutsideClick = ({ ref, event, isOpen, setIsOpen }) => {
  if (isOpen && ref?.current && !ref.current.contains(event?.target))
    setIsOpen(false);
};

export default handleOutsideClick;
