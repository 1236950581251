import styled from 'styled-components';

const StyledNavWrapper = styled.div`
  width: fit-content;
  gap: 10px;
  padding: 12px;

  display: flex;
  flex-direction: row;

  font-size: 12px;
  font-weight: 700;
  color: #001e5f;

  background-color: #dee6f8;
  box-shadow: 0px 0px 5px 0 #00000029;
`;

export default StyledNavWrapper;
