const dailyInOutKeys = {
  all: ['DAILY_IN_OUT'],
  list: ({ div, fromDate, toDate, yard, equipmentType, equipment }) => [
    ...dailyInOutKeys.all,
    'LIST',
    div,
    fromDate,
    toDate,
    yard,
    equipmentType,
    equipment,
  ],
  selected: ({ seq }) => [...dailyInOutKeys.all, 'SELECTED', seq],
  truckList: ({ div, yard }) => [
    ...dailyInOutKeys.all,
    'TRUCK_LIST',
    div,
    yard,
  ],
  containerList: ({ div, yard }) => [
    ...dailyInOutKeys.all,
    'CONTAINER_LIST',
    div,
    yard,
  ],
  trailerList: ({ div, yard }) => [
    ...dailyInOutKeys.all,
    'TRAILER_LIST',
    div,
    yard,
  ],
  chassisList: ({ div, yard }) => [
    ...dailyInOutKeys.all,
    'CHASSIS_LIST',
    div,
    yard,
  ],
};

export default dailyInOutKeys;
