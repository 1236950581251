import dayjs from 'dayjs';

const generateAgingDate = createdDate => {
  if (createdDate) {
    const currentDate = dayjs();
    const dateDiff = currentDate.diff(dayjs(createdDate), 'day');
    return dateDiff;
  }
  return null;
};

export default generateAgingDate;
