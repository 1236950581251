import styled from 'styled-components';

const StyledSidebarMenuIcon = styled.div`
  display: flex;
  align-items: center;

  height: 100%;
  width: 67px;

  background-color: transparent;

  position: relative;
`;

export default StyledSidebarMenuIcon;
