import { memo } from 'react';
import styled from 'styled-components';

const StyledSidebarMenuName = memo(
  styled.div`
    display: flex;
    align-items: center;

    font-size: 14px;

    color: ${props => (props?.$isCurrentMenu ? `#001E5F` : `#000000de`)};
    font-weight: ${props => (props?.$isCurrentMenu ? `bold` : `normal`)};
  `,
  null,
);

export default StyledSidebarMenuName;
