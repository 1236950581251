import { DAILY_IN_OUT_EQUIPMENT_TYPE } from '../../../constant/DailyInOut/elements';
import handleQueryError from '../../../util/Common/Error/Handler/handleQueryError';
import dailyInOutDefaultAPI from '../api';

const getDailyInOutList = async ({
  div,
  fromDate,
  toDate,
  yard,
  equipmentType,
  equipment,
}) => {
  try {
    const params = {
      ...(div && { div }),
      ...(fromDate && { from_date: fromDate }),
      ...(toDate && { to_date: toDate }),
      ...(yard && { yard }),
    };

    if (equipmentType && equipment) {
      const equipmentTypeKey = DAILY_IN_OUT_EQUIPMENT_TYPE[equipmentType];
      params[equipmentTypeKey] = equipment;
    }

    const response = await dailyInOutDefaultAPI.get('', { params });
    return response;
  } catch (error) {
    throw new Error(handleQueryError({ error }));
  }
};

export default getDailyInOutList;
