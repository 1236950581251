import { memo } from 'react';
import styled from 'styled-components';

const StyledSidebarMenuItem = memo(
  styled.div`
    display: flex;
    align-items: center;
    flex-direction: row;

    position: relative;

    width: 270px;
    height: 60px;

    background-color: ${props =>
      props?.$isCurrentMenu ? `rgba(25, 118, 210, 0.08)` : `white`};

    cursor: pointer;

    transition-duration: 300ms;
    &:hover {
      background-color: rgba(242, 244, 248);
    }
  `,
  null,
);

export default StyledSidebarMenuItem;
