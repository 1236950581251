const clickGridRow = ({ setSpecificSeq, setModalOpen, ...props }) => {
  const inventoryPage = document?.URL?.split('/')?.pop();
  const { gridParam, preventTargetColumnID } = props;
  const getColID = gridParam?.api?.getFocusedCell()?.column?.getColId();
  if (
    !preventTargetColumnID?.includes(getColID) &&
    inventoryPage !== 'linked-equips'
  ) {
    setSpecificSeq?.(props?.gridParam?.data?.seq);
    setModalOpen?.(prev => true);
  }
};

export default clickGridRow;
