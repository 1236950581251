import { get } from 'react-hook-form';
import { FormHelperText } from '@mui/material';
import React from 'react';

const ControllerError = ({
  errors,
  inputName,
  suppressErrorVisible,
  suppressErrorContentVisible,
}) => {
  return (
    !suppressErrorVisible && (
      <FormHelperText className="text-[#ff2424] text-[11px] font-normal min-h-[11px] h-[11px] mb-[3px] leading-none">
        {!suppressErrorContentVisible &&
          ((errors?.[inputName] && errors?.[inputName]?.message) ||
            (get(errors, inputName) && get(errors, inputName)?.message))}
      </FormHelperText>
    )
  );
};

export default ControllerError;
