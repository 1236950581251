import styled from 'styled-components';

const StyledSubMenuNavigatorContentsWrapper = styled.div`
  width: 100%;
  padding-top: 10px;
  flex: 1;

  display: flex;
  flex-direction: row;

  gap: 50px;
`;

export default StyledSubMenuNavigatorContentsWrapper;
