import { memo } from 'react';
import styled from 'styled-components';

const StyledSidebarMenuArrow = memo(
  styled.div`
    display: flex;
    align-items: center;
    justify-content: center;

    background-color: transparent;

    position: absolute;
    right: 15px;
  `,
  null,
);

export default StyledSidebarMenuArrow;
