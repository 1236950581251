import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import signInKeys from './keys';
import getUserInfo from '../../service/SignIn/Get/getUserInfo';
import { setAllDiv } from '../../redux/Feature/Common';

const useGetUserInfo = ({ setEssentialLoading }) => {
  const dispatch = useDispatch();
  /** userID */
  const userID = localStorage?.getItem('userId');

  const query = useQuery({
    queryKey: signInKeys.userInfo({
      userId: userID,
    }),
    queryFn: () => getUserInfo(),
    enabled: !!userID,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (query?.data?.data) {
      setEssentialLoading(prev => true);
      const result = query.data.data;
      localStorage.setItem('permission', result.permissions);
      localStorage.setItem('allDiv', result.all_div);
      localStorage.setItem('defaultDiv', result.default_div);
      dispatch(setAllDiv(result.all_div));
      // Set nowDiv in sessionStorage
      if (!sessionStorage.getItem('currDiv')) {
        const defaultDiv = localStorage.getItem('defaultDiv');
        if (defaultDiv) {
          sessionStorage.setItem('currDiv', defaultDiv);
        }
      }
      setEssentialLoading(prev => false);
    }
  }, [query?.data]);

  return { ...query };
};

export default useGetUserInfo;
