import styled from 'styled-components';

const StyledInventoryDatagridWrapper = styled.div`
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 100%;
  padding-right: 40px;
`;

export default StyledInventoryDatagridWrapper;
