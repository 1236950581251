import React, { useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import CommonButton from '../../../component/Common/Controller/Button/index';
import {
  PRESET_PRIMARY,
  PRESET_GREEN,
} from '../../../constant/Common/Style/preset';
import { PATH_COMMON_BUTTON_ICON } from '../../../constant/Common/Path/Asset/Icon/Button/index';
import createAccidentColumnDefs from '../../../util/Safety/Accident/Generator/createAccidentColumnDefs';
import CommonDataGrid from '../../../component/Common/Datagrid/index';
import exportToExcel from '../../../util/Common/Datagrid/Handler/exportToExcel';
import AccidentFormModal from '../../../component/Safety/Accident/AccidentFormModal';
import { EDIT_MODE } from '../../../constant/Safety/Common/mode';
import { ALERT_MODAL_STATUS } from '../../../constant/Common/Modal/Alert/status';
import CommonAlert from '../../../component/Common/Modal/Alert';
import CommonLoading from '../../../component/Common/Loading/index';
import useGetAccidentList from '../../../hook/Safety/Accident/Get/useGetAccidentList';
import useControlLoadingStatus from '../../../hook/Safety/Common/Loading/useControlLoadingStatus';
import useGetAccident from '../../../hook/Safety/Accident/Get/useGetAccident';
import handleRowClicked from '../../../util/Safety/Accident/Handler/handleRowClicked';
import generateAccidentDefaultValue from '../../../util/Safety/Accident/Generator/generateAccidentDefaultValue';
import usePostAccident from '../../../hook/Safety/Accident/Post/usePostAccident';
import usePatchAccident from '../../../hook/Safety/Accident/Patch/usePatchAccident';
import useGetGridFilterOption from '../../../hook/Safety/Common/Option/useGetGridFilterOption';
import checkPermission from '../../../util/Common/Router/Handler/checkPermission';
import { PERM_SAFETY_ACCIDENT_EDIT } from '../../../constant/Common/Permission';
import TotalBottomBar from '../../../component/Common/TotalBottomBar';
import useControlTotalInfo from '../../../hook/Common/TotalBottomBar/useControlTotalInfo';

const Accident = () => {
  const driverList = useSelector(state => state?.common?.driverList);

  const [nameOptionList, setNameOptionList] = useState([]);
  const [nameInfoObject, setNameInfoObject] = useState({});

  useEffect(() => {
    const tempOptionList = [];
    const tempObject = {};

    driverList?.forEach((name, index) => {
      tempOptionList?.push({
        key: index,
        label: `${name?.id}/${name?.name}`,
        value: name.id,
      });

      tempObject[name?.id] = name?.type;
    });

    setNameOptionList(prev => tempOptionList);
    setNameInfoObject(prev => tempObject);
  }, [driverList]);

  const isEditable = checkPermission({ permission: PERM_SAFETY_ACCIDENT_EDIT });

  /** DataGrid */
  const gridRef = useRef(null);
  const [columnDefs, setColumnDefs] = useState([]);

  /** Alert Modal */
  const [isOpenAlert, setIsOpenAlert] = useState(false);
  const [alertInfo, setAlertInfo] = useState({
    status: ALERT_MODAL_STATUS?.STABLE,
    message: '',
  });

  const setAlertOptions = { setIsOpenAlert, setAlertInfo };

  /** Form Modal */
  const [isOpenFormModal, setIsOpenFormModal] = useState(false);
  const [modalType, setModalType] = useState(EDIT_MODE?.add);

  /** Get Accident List */
  const {
    accidentList,
    isLoading: isLoadingAccidentList,
    isFetching: isFetchingAccidentList,
    isError: isErrorAccidentList,
  } = useGetAccidentList();

  useEffect(() => {
    if (isErrorAccidentList) {
      setAlertInfo(prev => {
        return {
          status: ALERT_MODAL_STATUS.ERROR,
          message: 'Failed to fetch accident list',
        };
      });
      setIsOpenAlert(prev => true);
    }
  }, [isErrorAccidentList]);

  /** Get Specific Accident  */
  const {
    seq,
    setSeq,
    accidentInfo,
    isLoading: isLoadingAccident,
    isFetching: isFetchingAccident,
  } = useGetAccident({ setAlertOptions });

  /** Post Accident */
  const { mutate: postAccidentMutator, isPending: isPendingPostAccident } =
    usePostAccident({ setAlertOptions, setIsOpenFormModal });

  /** Patch Accident */
  const { mutate: patchAccidentMutator, isPending: isPendingPatchAccident } =
    usePatchAccident({
      gridRef,
      setAlertOptions,
      setIsOpenFormModal,
    });

  /** Form Default Value */
  const [formDefaultValue, setFormDefaultValue] = useState(
    generateAccidentDefaultValue({
      modalType,
      accidentInfo,
    }),
  );

  /** Form */
  const accidentFormController = useForm({
    shouldUnregister: true,
    defaultValues: formDefaultValue,
  });

  useEffect(() => {
    const newFormDefaultValue = generateAccidentDefaultValue({
      modalType,
      accidentInfo,
    });

    accidentFormController.reset(newFormDefaultValue);
    setFormDefaultValue(newFormDefaultValue);
  }, [isOpenFormModal, modalType, accidentInfo]);

  /** Loading */
  const { isLoadingOpen } = useControlLoadingStatus({
    isFetching: [isFetchingAccident],
    isLoading: [isLoadingAccidentList, isLoadingAccident],
    isPending: [],
  });

  /** Filter option */
  const { filterDivisionList } = useGetGridFilterOption();

  useEffect(() => {
    if (filterDivisionList?.length > 1) {
      setColumnDefs(createAccidentColumnDefs({ filterDivisionList }));
    }
  }, [filterDivisionList]);

  useEffect(() => {
    if (!isOpenFormModal) {
      setSeq(null);
    }
  }, [isOpenFormModal]);

  const { totalRows, setChangeFlag } = useControlTotalInfo({
    gridRef,
    queryData: accidentList,
  });

  return (
    <>
      <div className="pl-[29px] flex flex-col flex-1 pb-8">
        <div className="mt-[20px] mb-[20px] flex justify-end gap-x-[10px] pr-[40px]">
          <CommonButton
            styles={PRESET_GREEN}
            icon={PATH_COMMON_BUTTON_ICON?.excelGreen}
            onClick={() => {
              exportToExcel({ gridRef, fileName: 'ACCIDENT' });
            }}
          >
            EXCEL
          </CommonButton>
          {isEditable && (
            <CommonButton
              styles={PRESET_PRIMARY}
              icon={PATH_COMMON_BUTTON_ICON?.plusBlue}
              onClick={() => {
                setIsOpenFormModal(true);
                setModalType(EDIT_MODE?.add);
              }}
            >
              Add
            </CommonButton>
          )}
        </div>
        <div className="flex flex-col w-full h-full pr-[40px]">
          <CommonDataGrid
            gridRef={gridRef}
            columnDefs={columnDefs}
            data={accidentList}
            onRowClicked={props =>
              handleRowClicked({
                props,
                setSeq,
                setIsOpenFormModal,
                setModalType,
              })
            }
            onFilterChanged={() => {
              setChangeFlag(prev => !prev);
            }}
            onSelectionChanged={() => {
              setChangeFlag(prev => !prev);
            }}
          />
          <TotalBottomBar totalRows={totalRows} />
        </div>
      </div>
      <AccidentFormModal
        seq={seq}
        modalType={modalType}
        isOpenFormModal={isOpenFormModal}
        setIsOpenFormModal={setIsOpenFormModal}
        accidentFormController={accidentFormController}
        accident={formDefaultValue}
        postAccidentMutator={postAccidentMutator}
        patchAccidentMutator={patchAccidentMutator}
        nameOptionList={nameOptionList}
        nameInfoObject={nameInfoObject}
        isEditable={isEditable}
      />
      <CommonLoading isOpen={isLoadingOpen} />
      {isOpenAlert && alertInfo?.status !== ALERT_MODAL_STATUS?.SUCCESS && (
        <CommonAlert
          isOpen={isOpenAlert}
          content={alertInfo?.message}
          status={alertInfo?.status}
          onCloseButtonClick={() => setIsOpenAlert(false)}
          onOKButtonClick={() => setIsOpenAlert(false)}
        />
      )}
    </>
  );
};

export default Accident;
