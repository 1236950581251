import dayjs from 'dayjs';

const checkDateDiff = ({ fromDate, toDate }) => {
  if (
    fromDate &&
    toDate &&
    dayjs(fromDate)?.isValid() &&
    dayjs(toDate)?.isValid()
  ) {
    const convertedFromDate = dayjs(fromDate);
    const convertedToDate = dayjs(toDate);

    const dayDiff = convertedToDate?.diff(convertedFromDate, 'day');
    if (dayDiff <= 365 && dayDiff >= 0) return true;
    return false;
  }
  return false;
};

export default checkDateDiff;
