import styled from 'styled-components';

const StyledUtilityBarContainer = styled.div`
  width: 100%;
  height: fit-content;
  padding: 0 40px 0 0;
  margin-top: 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: end;

  .utility-button-field {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
    margin-bottom: 15px;
  }
`;

export default StyledUtilityBarContainer;
