const useGetExcelStyles = () => {
  const excelStyles = [
    {
      id: 'date',
      dataType: 'DateTime',
      numberFormat: {
        format: 'MM/DD/YYYY',
      },
    },
    {
      id: 'datetime',
      dataType: 'DateTime',
      numberFormat: {
        format: 'MM/DD/YY HH:mm',
      },
    },

    /* Setting Excel cell background color */
    {
      id: 'trkDmgColor',
      interior: {
        pattern: 'Solid',
        color: '#CCD3E3',
      },
      font: {
        bold: true,
      },
    },
    {
      id: 'chasDmgColor',
      interior: {
        pattern: 'Solid',
        color: '#909FC1',
      },
      font: {
        bold: true,
      },
    },
  ];
  return { excelStyles };
};

export default useGetExcelStyles;
