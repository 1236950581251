const getAuthorizedMenu = ({ allMenu = [] }) => {
  const userPermission = localStorage.getItem('permission')?.split(',');

  return allMenu?.filter(menu => {
    return userPermission?.some(permission =>
      menu?.permission?.includes(permission),
    );
  });
};

export default getAuthorizedMenu;
