import dayjs from 'dayjs';
import {
  REGEX_SHORT_DATE,
  REGEX_SHORT_DATE_TIME,
} from '../../../../constant/Common/Regex';

/**
 * @description
 * Function to process cell values, particularly for formatting date and time.
 * @param {object} params - Parameters containing the cell value.
 * @returns {string} - Processed cell value.
 */
const processCellCallback = params => {
  if (params?.value && typeof params?.value === 'string') {
    const isDateField = REGEX_SHORT_DATE?.test(params?.value);
    const isDateTimeField = REGEX_SHORT_DATE_TIME?.test(params?.value);

    if (isDateField || isDateTimeField) {
      return dayjs(params?.value)?.format('YYYY-MM-DDTHH:mm:ss');
    }

    return params?.value;
  }
  return params?.value;
};

/**
 * @description
 * Function to handle excel export.
 * @param {object} gridRef
 * Reference to the grid.
 * @returns
 * Function to export data as excel.
 */
const exportToExcel = ({ gridRef, fileName, suppressCurrentDate }) => {
  const currFullDate = new Date();
  const currYear = currFullDate.getFullYear();
  const currMonth = currFullDate.getMonth() + 1;
  const currDate = currFullDate.getDate();

  const formattedDate = `${currMonth < 10 ? `0${currMonth}` : currMonth}${
    currDate < 10 ? `0${currDate}` : currDate
  }${currYear.toString().slice(-2)}`;

  const excelFileName = fileName
    ? `${fileName}${suppressCurrentDate ? '' : `_${formattedDate}`}`
    : `EXCEL${suppressCurrentDate ? '' : `_${formattedDate}`}`;

  const getSelectedRow = gridRef?.current?.api?.getSelectedRows();
  if (getSelectedRow && getSelectedRow.length > 0) {
    return gridRef?.current?.api?.exportDataAsExcel({
      fileName: excelFileName,
      onlySelected: true,
      processCellCallback,
    });
  }
  return gridRef?.current?.api?.exportDataAsExcel({
    fileName: excelFileName,
    processCellCallback,
  });
};

export default exportToExcel;
