import React, { useEffect, useRef, useState } from 'react';
import CommonDataGrid from '../../../component/Common/Datagrid';
import CommonLoading from '../../../component/Common/Loading';
import CommonAlert from '../../../component/Common/Modal/Alert';
import { GRID_MOD_BOTH } from '../../../constant/Common/DataGrid/setting';
import { ALERT_MODAL_STATUS } from '../../../constant/Common/Modal/Alert/status';
import useDeleteGroupMutation from '../../../hook/Admin/Auth/Delete/useDeleteGroupMutation';
import useGetGroup from '../../../hook/Admin/Auth/Get/useGetGroup';
import usePatchGroupMutation from '../../../hook/Admin/Auth/Patch/usePatchGroupMutation';
import usePostGroupMutation from '../../../hook/Admin/Auth/Post/usePostGroupMutation';
import adminAuthColumnDefinitions from '../../../util/Admin/Auth/Generator/createAdminAuthColumnDefs';
import handleAddClicked from '../../../util/Admin/Auth/Handler/handleAddClicked';
import handleDeleteClicked from '../../../util/Admin/Auth/Handler/handleDeleteClicked';
import handleSaveClicked from '../../../util/Admin/Auth/Handler/handleSaveClicked';

const Auth = () => {
  const gridRef = useRef(null);
  const [isMessageOpen, setIsMessageOpen] = useState(false);
  const [message, setMessage] = useState('');
  const [messageStatus, setMessageStatus] = useState('');
  const [authList, setAuthList] = useState([]);

  const { isLoadingGroup, data, isRefetchingGroup, isErrorGroup } =
    useGetGroup();

  useEffect(() => {
    setAuthList(data?.data);
  }, [data]);

  const { mutate: patchGroupMutation } = usePatchGroupMutation({
    gridRef,
    setIsMessageOpen,
    setMessage,
    setMessageStatus,
  });

  const { mutate: postGroupMutation } = usePostGroupMutation({
    setIsMessageOpen,
    setMessage,
    setMessageStatus,
    gridRef,
  });

  const { mutate: deleteGroupMutation } = useDeleteGroupMutation({
    setIsMessageOpen,
    setMessage,
    setMessageStatus,
  });

  useEffect(() => {
    if (isErrorGroup) {
      setMessageStatus(ALERT_MODAL_STATUS.ERROR);
      setIsMessageOpen(true);
      setMessage('Error occurred while fetching data');
    }
  }, [isErrorGroup]);

  const [columnDefs, setColumnDefs] = useState(adminAuthColumnDefinitions());

  return (
    <>
      <CommonLoading isOpen={isLoadingGroup || isRefetchingGroup} />
      {messageStatus !== ALERT_MODAL_STATUS.SUCCESS && (
        <CommonAlert
          isOpen={isMessageOpen}
          setIsOpen={setIsMessageOpen}
          status={messageStatus}
          content={message}
          onOKButtonClick={() => setIsMessageOpen(false)}
          onCloseButtonClick={() => setIsMessageOpen(false)}
        />
      )}
      {authList && (
        <div className="flex flex-col pl-[29px] pb-8 h-full pr-[40px]">
          <div className="mt-8" />
          <div className="w-full h-full flex-1">
            <CommonDataGrid
              addable
              pinnedCheckbox
              modType={GRID_MOD_BOTH}
              gridRef={gridRef}
              data={authList}
              columnDefs={columnDefs}
              onSaveClicked={e => handleSaveClicked({ e, patchGroupMutation })}
              onAddClicked={e => handleAddClicked({ e, postGroupMutation })}
              onDeleteClicked={e =>
                handleDeleteClicked({ e, deleteGroupMutation })
              }
            />
          </div>
        </div>
      )}
    </>
  );
};

export default Auth;
