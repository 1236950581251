import { useMutation, useQueryClient } from '@tanstack/react-query';
import { SUCCESS_SAVE } from '../../../constant/Common/Message/success';
import { ALERT_MODAL_STATUS } from '../../../constant/Common/Modal/Alert/status';
import postDailyInOut from '../../../service/DailyInOut/Post/postDailyInOut';
import updateButtonDisabled from '../../../util/Common/Controller/Handler/updateButtonDisabled';
import useControlToast from '../../Common/Toast/useControlToast';
import dailyInOutKeys from '../keys';

const usePostDailyInOutMutation = ({
  setIsMessageOpen,
  setMessage,
  setMessageStatus,
  div,
  fromDate,
  toDate,
  yard,
  setIsAddEditModalOpen,
  addEditModalController,
  equipmentType,
  equipment,
}) => {
  const queryClient = useQueryClient();
  const { addToast } = useControlToast();

  return useMutation({
    mutationFn: postDailyInOut,
    onSettled: (data, error, variables) => {
      if (variables?.buttonRef)
        updateButtonDisabled({ ref: variables.buttonRef, disabled: false });
    },
    onSuccess: () => {
      setIsAddEditModalOpen(false);
      addEditModalController.reset();
      queryClient.refetchQueries({
        queryKey: dailyInOutKeys?.list({
          div,
          fromDate,
          toDate,
          yard,
          equipmentType,
          equipment,
        }),
      });
      addToast?.({ message: SUCCESS_SAVE });
    },
    onError: error => {
      setMessageStatus(ALERT_MODAL_STATUS?.ERROR);
      setMessage(error.message);
      setIsMessageOpen(true);
    },
  });
};

export default usePostDailyInOutMutation;
