import FloatingFilterInput from '../../../../component/Common/Datagrid/Component/FloatingFilterRenderer/FloatingFilterInput';
import dateComparator from '../../../Common/Datagrid/Comparator/dateComparator';
import {
  generateFormattedDay,
  generateFormattedTime,
} from '../../Common/Generator/formattedDayGenerator';

const createChassisColumnDefs = () => {
  return [
    {
      headerName: 'DATE',
      field: 'created_date',
      width: 62,
      editable: false,
      cellClass: 'date',
      valueGetter: params =>
        generateFormattedDay(params?.data?.created_date, 'MM/DD/YY'),
      comparator: dateComparator,
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'date',
        maxLength: 8,
        placeholder: 'MM/DD/YY',
      },
    },
    {
      headerName: 'TIME',
      field: 'created_time',
      width: 48,
      editable: false,
      valueGetter: params => generateFormattedTime(params?.data?.created_time),
      floatingFilterComponent: FloatingFilterInput,
      floatingFilterComponentParams: {
        inputType: 'time',
        maxLength: 5,
        placeholder: 'HH:mm',
      },
    },
    {
      headerName: 'YARD',
      field: 'yard',
      width: 70,
      editable: false,
      floatingFilter: false,
    },
    {
      headerName: 'LOC',
      field: 'yard_location',
      width: 72,
      editable: false,
    },
    {
      headerName: 'CHASSIS',
      field: 'ch_no',
      width: 86,
      editable: false,
    },
    {
      headerName: 'TYPE',
      field: 'type',
      width: 70,
      editable: false,
    },
    {
      headerName: 'POOL',
      field: 'pool',
      width: 52,
      editable: false,
    },
    {
      headerName: 'REMARKS',
      field: 'remarks',
      editable: false,
    },
  ];
};

export default createChassisColumnDefs;
