const handleAllCheckboxChange = ({ e, setQuery, ...handleProps }) => {
  handleProps?.options?.forEach(checkbox => {
    setQuery(prev => {
      return { ...prev, [checkbox?.value]: e?.target?.checked };
    });
    handleProps?.setValue(checkbox?.value, e?.target?.checked);
  });
};

export default handleAllCheckboxChange;
